import './style.scss';
import { SpeakerModel } from "./Speaker/SpeakerModel";
import { Speaker } from "./Speaker/Speaker";
import { Slider } from '../../../js/components/Slider/Slider';

export default !function () {
  const wrapper = document.querySelector('.speakers-v2__wrapper');

  if (!wrapper) {
    return;
  }

  const list = wrapper.querySelector('.speakers-v2__list');
  const items = list.querySelectorAll('.speakers-v2__item');
  const controls = wrapper.querySelector('.speakers-v2__controls');

  const options = {
    wrapper,
    settings: {
      loop: false,
      spaceBetween: 8,
      slideClass: 'speakers-v2__item',
      wrapperClass: 'speakers-v2__list',
      freeMode: true,

      pagination: {
        el: '.speakers-v2__current',
        type: 'fraction'
      },
      navigation: {
        nextEl: '.button-arrow--next',
        prevEl: '.button-arrow--prev',
        disabledClass: 'button-arrow--disabled'
      },

      breakpoints: {
        768: {
          spaceBetween: 24,
          slidesPerView: 2
        },
        1024: {
          spaceBetween: 32,
          slidesPerView: 3,
          slidesPerGroup: 3,
          freeMode: false
        },
        1280: {
          spaceBetween: 32,
          slidesPerGroup: 4,
          slidesPerView: 4,
          freeMode: false
        }
      }
    },
    modules: ['pagination', 'navigation', 'freeMode', 'a11y'],
    initCondition: {
      sm: items.length > 3,
      md: items.length > 4,
      lg: items.length > 6,
      xl: items.length > 8
    },
    controls,
    createCallback: () => {
      list.classList.remove('speakers-v2__list--static');
      list.classList.add('speakers-v2__list--slider');
    },
    destroyCallback: () => {
      list.classList.add('speakers-v2__list--static');
      list.classList.remove('speakers-v2__list--slider');
    }
  };

  new Slider(options).init();

  /* Preview Popup */
  const speakerModel = new SpeakerModel();
  const blockName = document.querySelector('.speakers-v2').dataset.blockName ?? 'Блок без названия'

  list.addEventListener('click', (evt) => {
    evt.preventDefault();

    const card = evt.target.closest('.ui-speaker-card__link[href]');

    if (card) {
      const hasWorks = card.dataset.works;
      const hasWorksValue = JSON.parse(hasWorks);

      new Speaker(card, list, speakerModel, hasWorksValue, blockName).init();
      window.$analytics && window.$analytics.push('teachers-card-click');
    }
  });
}();
